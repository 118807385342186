import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {  Button, Grid, TableContainer, Typography } from '@mui/material';


export const ConsultBallot: FunctionComponent = (props: any) => {

   function createData(
      name: string,
      calories: number,
      fat: number,
      carbs: number,
      protein: number,
    ) {
      return { name, calories, fat, carbs, protein };
    }

   const rows = [
      createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
      createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
      createData('Eclair', 262, 16.0, 24, 6.0),
      createData('Cupcake', 305, 3.7, 67, 4.3),
      createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];
    
   return (
      <Grid>
         <div>
            <Box sx={{ flexGrow: 1, padding: 4 }}>
               <AppBar>
                  <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', bgcolor: '#176ECA' }}>
                     <Button color="inherit">
                        <Typography
                           variant="h6"
                           noWrap
                           component="div"
                           sx={{ display: { xs: 'block', sm: 'block' } }}
                        >
                           NUPY LOYVERSE
                        </Typography>
                     </Button>
                     <Button color="inherit" >
                        Cerrar Sesion
                     </Button>

                  </Toolbar>
               </AppBar>
            </Box>
         </div>
         <div className='col-xs-12 col-md-offset-3 text-dark' style={{ padding: 40, textAlign: "center", minHeight: "100vh" }}>
            <Grid container justifyContent='space-between' mb={4}>
               <Grid item>
                  <Typography variant='h3'>Consulta tu boleta</Typography>
               </Grid>
               
            </Grid>
            <TableContainer component={Paper}>
               <Table aria-label="simple table" >
                  <TableHead sx={{ bgcolor: '#176ECA', color: '#fff' }}>
                     <TableRow >
                        <TableCell sx={{ color: '#fff' }}>Dessert </TableCell>
                        <TableCell sx={{ color: '#fff' }}>Calories</TableCell>
                        <TableCell sx={{ color: '#fff' }}>Fat&nbsp;(g)</TableCell>
                        <TableCell sx={{ color: '#fff' }}>Carbs&nbsp;(g)</TableCell>
                        <TableCell sx={{ color: '#fff' }}>Protein&nbsp;(g)</TableCell>
                        <TableCell sx={{ color: '#fff' }}>Acciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rows.map((row) => (
                        <TableRow
                           key={row.name}
                           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                           <TableCell component="th" scope="row">
                              {row.name}
                           </TableCell>
                           <TableCell>{row.calories}</TableCell>
                           <TableCell>{row.fat}</TableCell>
                           <TableCell>{row.carbs}</TableCell>
                           <TableCell>{row.protein}</TableCell>
                           <TableCell>
                              <Button variant="contained" color="info">Ver</Button>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>

         </div>
      </Grid>

   )
} 