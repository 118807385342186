import React from "react";

import "./CalculatorKey.css";
function CalculatorKey(props) {

  // const handleKeyPress = (event) => {
  //   console.log(event)
    
  //     props.onClick('5')
    
  // }

  return (
    <button
      id= 'button-calculadora'
      //className={`${props.className}`}
      style={{width:'3.2rem',
      height:'3.2rem',
      margin:'0.4rem' ,
      textAlign:'center',
      fontSize:'150%',
      borderRadius:'0.8rem'}}
      // onKeyPress={handleKeyPress}
      onClick={() => props.onClick(props.keyValue)}
    >
      {props.keyValue}{" "}
    </button>
    
  );
}

export default CalculatorKey;