import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Backdrop, Button, CircularProgress, FormControl, Grid, MenuItem, Select, Snackbar, TableContainer, Typography } from '@mui/material';
import { saleService } from '@service/services/Sale.service';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './SeccionReporte';
//import "./GeneratorTicket.sass";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_AREA, KEY_BODEGA, KEY_EMPRESA, KEY_PAGOS, KEY_PARMS, KEY_TOKEN, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Protected } from '@/components/layout/Protected';
import { FormatDouble,moneyFormat, moneyFormatInt } from '@/toolbox/helpers/money.helper'
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useMediaQuery, useTheme } from '@mui/material';

export const GenerateProcessedCanceled: FunctionComponent = (props: any) => {

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
   const [data, setData] = useState(null);
   const [filter, setFilter] = useState(null);
   const [open, setOpen] = useState(false);
   const componentRef = useRef();
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   // Modificar acá y traer Data dinámica
   const [dataSale, setDataSale] = useState<any>([])
   useEffect(() => {
      getSalesPending();
     // setFilter(0)
   }, [])

   async function getSalesPending() {
      setOpen(true)
      const parms = readLocalStorage(KEY_PARMS)
      const resp = await saleService.getSales(parms,4)
      setDataSale(resp.contact)
      setOpen(false)
   }

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         // backgroundColor: theme.palette.common.black,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: { fontSize: 14 },
   }));

   const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
      // hide last border
      '&:last-child td, &:last-child th': { border: 0 },
   }));

   useEffect(() => {
      if (data !== null) {
         handlePrint();
      }
   }, [data])


   const handleChangeFilter = (e) => {
      setFilter(e.target.value)
      let newArray;
      const pos = e.target.value;
      switch (pos) {
         case 0:
            getSalesPending();
            break;
         case 1:
            newArray = dataSale.sort(function (a, b) {
               if (a.payload.created_at > b.payload.created_at) {
                  return 1;
               }
               if (a.payload.created_at < b.payload.created_at) {
                  return -1;
               }
               return 0;
            })
            setDataSale(newArray)
            break;
      }
   }
   
   const recuperarData = async (i) => {
      
       const data = dataSale[i];
       let dataUser;
       dataUser = readLocalStorage(KEY_USER_DATA);
       let dataEmpresa;
       dataEmpresa = readLocalStorage(KEY_EMPRESA)
       const token = readLocalStorage(KEY_TOKEN)
       const rutEmpresa = readLocalStorage(KEY_PARMS)
       const bodega = readLocalStorage(KEY_BODEGA)
       const pagos = readLocalStorage(KEY_PAGOS)
       const area = readLocalStorage(KEY_AREA)
       //console.log(rutEmpresa)
       // const areaNegocio = await saleService.getAreaNegocio(token);
       // setArea(areaNegocio[0].id)
       // const bodegas = await saleService.getBodega(token);
       // setBodega(bodegas[0].id)
       // const formaPagos = await saleService.getTipoPagos(token);
       // setPagos(formaPagos[0].id);
       //pagos.find(({ id }) => nombre === 'Efectivo')
 
       // const lineItems = data.payload.receipts[0].line_items.map((value, i) => {
       //    const dataLine = {
       //       Codigo:1,
       //       Cantidad: value.quantity%1==0?value.quantity:1,
       //       Descuento:0,
       //       Precio: value.quantity%1==0?value.price:value.price*value.quantity,
       //       data.payload.receipts[0].total_money-data.payload.receipts[0].total_tax,
       //       Afecto:true,
       //       Bodega: bodegas[0].id,
       //    }
       //    return  dataLine
       // })
 
       let propina=0;
       let type='';
 
       data.payload.receipts[0].total_taxes.map((value, i)=>{
          if(value.name=='Propina'){
             propina=  value.money_amount
          }
          if(value.name=='IVA'){
             type= value.type
          }
       })
 
 
       let total_bruto = data.payload.receipts[0].total_money-propina;
       let total_neto = total_bruto/1.19
 
       let total_neto_2= Math.ceil(total_neto)
 
 
       const lineItems = [
         { Codigo:1,
          Cantidad: 1,
          Descuento:0,
          Precio: total_neto_2,
          Afecto:true,
          Bodega: bodega}
       ]
 
 
 
 
       const enviarBoleta  = [
          {
            Encabezado: {
              Receptor: '66666666-6',
              MontoNeto: total_neto_2,
              //type=='ADDED'?total_neto:total_bruto,
              Descuento: 0, //this.state.descuento,
              TipoDocumento: '39',
              AreaNegocio: area,
              Observacion: 'observacion',
              Direccion: 'santiago',
            },
            Detalle: lineItems,
            cliente: 'cliente',
            Adicional: {
              Uno: pagos,
              Dos: dataUser.rut_usuario,
              Treinta: '123',
             // Nueve: selectedPago.nombre,
            },
            fecha: new Date().toLocaleString(),
          },
        ]
        console.log(enviarBoleta)
      //  const boletagenerada = await saleService.enviarBoleta(token, enviarBoleta);
      //   console.log(boletagenerada)
 
      //  if(!boletagenerada[0]){
      //     alert('Los datos a procesar son inválidos')
      //     setDisabled(false)
      //  }else{
      //     setDisabled(false)
      //     if(boletagenerada[0].mensaje!=='Item guardado con exito.'){
      //        console.log(boletagenerada)
      //        alert(boletagenerada[0].mensaje)
      //        setDisabled(false)
      //      }else{
      //        const resptimbre = boletagenerada[0].urlTimbre
      //        console.log(total_bruto)
      //        console.log(total_neto)
      //        console.log(total_bruto-total_neto)
      //        console.log(Math.ceil(total_bruto-total_neto))
      //         const newData = {
      //           nameProyect: dataEmpresa?.razon,
      //           tipoBoleta:  `BOLETA ELECTRÓNICA ·  ${boletagenerada[0].folio}`,
      //           direccion: dataUser.sucursal[0].direccion,
      //           cliente: rutEmpresa.rut,
      //           anoreporte: data ? dateFormat(data.payload.created_at):'',
      //           horareporte: data ? dateFormatTime(data.payload.created_at):'',
      //           codigoProducto: "1000",
      //           subTotal: "any",
      //           montoNeto: moneyFormatInt(Math.ceil(total_neto)),
      //           type: type,
      //           iva: moneyFormatInt(total_bruto-total_neto),
      //           montoTotal: moneyFormatInt(Math.ceil(total_bruto)),
      //           timbre: resptimbre
      //           //timbreEstatico: timbre
      //        }
      //        setData(newData);
      //       dataSale.splice(i,1);
      //        const resp= await saleService.markSaleIssued(data._id,boletagenerada[0]);
      //        getSalesPending();
      //        setDisabled(false)
      //      }
      //  }
      //  setDisabled(false)
    }
   return (
      <Protected>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <CircularProgress color="inherit" />
         </Backdrop>
  <div className='col-xs-12 col-md-offset-3 text-dark' style={{ padding: 40, textAlign: "center", minHeight: "100vh" }}>
  <Grid container justifyContent='space-between' mb={4}>
            {isMobile?(
                  <Grid container justifyContent='center'>
                  <Typography variant='h4'>Boletas Anuladas</Typography>
                  </Grid>
               ):(
                  <Typography variant='h3'>Boletas Anuladas</Typography>
               )}
            {isMobile?
            (<></>):
            (<Grid item pt={2}>
               <Button variant="contained" color="success" onClick={()=>getSalesPending()} >
                  Actualizar
               </Button>
            </Grid>)
            }
         </Grid>
         {isMobile?
         (<>
         <Grid container justifyContent='space-between' mb={3}>
            <Grid item pt={2}>
               <Button variant="contained" color="success" onClick={()=>getSalesPending()} >
                  <RefreshIcon/>
               </Button>
            </Grid>
            <Grid
            item mt={1}>
            {/* <Grid >
               Filter:
            </Grid> */}
            <Grid >
               <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                  {/* <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel> */}
                  <Select
                     labelId="demo-simple-select-standard-label"
                     id="demo-simple-select-standard"
                     value={filter}
                     onChange={handleChangeFilter}
                     label="Age"
                  >
                     <MenuItem value={0}>Desc</MenuItem>
                     <MenuItem value={1}>Asc</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
             </Grid>
         </Grid>
         </>):(
            <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid >
               Filtro:
            </Grid>
            <Grid >
               <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                  {/* <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel> */}
                  <Select
                     labelId="demo-simple-select-standard-label"
                     id="demo-simple-select-standard"
                     value={filter}
                     onChange={handleChangeFilter}
                     label="Age"
                  >
                     <MenuItem value={0}>Desc</MenuItem>
                     <MenuItem value={1}>Asc</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
             </Grid>
         )}

         <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small'>
               <TableHead sx={{bgcolor:'#176ECA', color:'#fff'}}>
                  <TableRow >
                     {isMobile?(
                        <>
                        <TableCell align="left" sx={{color:'#fff'}}>FOLIO</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>N° RECIBO</TableCell>
                     <TableCell align="left" sx={{color:'#fff',minWidth:{xs:'7rem',lg:'5rem'}}}>TOTAL</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>FECHA</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>PRODUCTOS</TableCell>
                     <TableCell align="left" sx={{color:'#fff',minWidth:{xs:'7rem',lg:'5rem'}}}>TOTAL NETO</TableCell>

                        </>
                     ):
                     (
                        <>
                        <TableCell align="left" sx={{color:'#fff'}}>FOLIO</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>N° RECIBO</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>PRODUCTOS</TableCell>
                     <TableCell align="left" sx={{color:'#fff'}}>FECHA</TableCell>
                     <TableCell align="left" sx={{color:'#fff',minWidth:{xs:'7rem',lg:'5rem'}}}>TOTAL NETO</TableCell>
                     <TableCell align="left" sx={{color:'#fff',minWidth:{xs:'7rem',lg:'5rem'}}}>TOTAL</TableCell>
                        </>
                     )}

                  </TableRow>
               </TableHead>
               <TableBody>
                  {dataSale.map((row, i) => {
                     let cantidad = 0;
                     let length = row.payload.receipts[0].line_items.length;
                     let propina=0;

                     row.payload.receipts[0].total_taxes.map((value, i)=>{
                        if(value.name=='Propina'){
                           propina=  value.money_amount
                        }

                     })

                     let total_bruto = row.payload.receipts[0].total_money-propina;
                     let total_neto = total_bruto/1.19

                     if(row?.detalles_softnet[0]?.mensaje == "Item guardado con exito."){
                        return(
                           <StyledTableRow key={i}>
                              {isMobile?(
                                 <>
                                 <StyledTableCell>{ row?.detalles_softnet.length == 1 && row?.detalles_softnet[0]?.mensaje !== "Item guardado con exito." ? ('ERROR' ) : row?.detalles_softnet?.errorMessage? 'ERROR' : row.folio}</StyledTableCell>
                                 <StyledTableCell align="left">
                                 {row.payload.receipts[0].receipt_number}
                                 </StyledTableCell>
                                 <StyledTableCell align="left">{moneyFormatInt(Math.ceil(total_bruto))}</StyledTableCell>
                                 <StyledTableCell align="left">{dateFormat(row.payload.created_at)+' '+dateFormatTime(row.payload.created_at)}</StyledTableCell>
                                 <StyledTableCell align="left">
                                 {
                                    row.payload.receipts[0].line_items.map((value, i) => {
                                       cantidad = cantidad + value.quantity;
                                       if(i===length-1)
                                       {
                                          return (
                                             FormatDouble(cantidad)
                                          )
                                       }else
                                       {
   
                                       }
                                    })}
   
                                 </StyledTableCell>
                                 <StyledTableCell align="left">{moneyFormatInt(Math.ceil(total_neto))}</StyledTableCell>
   
                                 </>
                              ):
                              (
                                 <>
                                 <StyledTableCell>{ row?.detalles_softnet.length == 1 && row?.detalles_softnet[0]?.mensaje !== "Item guardado con exito." ? ('ERROR' ) : row?.detalles_softnet?.errorMessage? 'ERROR' : row.folio}</StyledTableCell>
                                 <StyledTableCell align="left">
                                 {row.payload.receipts[0].receipt_number}
                                 </StyledTableCell>
                                 <StyledTableCell align="left">
                                 {
                                    row.payload.receipts[0].line_items.map((value, i) => {
                                       cantidad = cantidad + value.quantity;
                                       if(i===length-1)
                                       {
                                          return (
                                             FormatDouble(cantidad)
                                          )
                                       }else
                                       {
   
                                       }
                                    })}
   
                                 </StyledTableCell>
                                 <StyledTableCell align="left">{dateFormat(row.payload.created_at)+' '+dateFormatTime(row.payload.created_at)}</StyledTableCell>
                                 <StyledTableCell>{moneyFormatInt(Math.ceil(total_neto))}</StyledTableCell>
                                       <StyledTableCell>{moneyFormatInt(Math.ceil(total_bruto))}</StyledTableCell>
                                       {/* <StyledTableCell align="left">
                                       <Button variant="contained" onClick={() => { recuperarData(i) }} color="success" sx={{ mr: 2 }}>
                                          Generar
                                       </Button>
                                 </StyledTableCell>  */}
     </>
                              )}
   
                        </StyledTableRow>
                        )
                     }
                    
})}
               </TableBody>
            </Table>
         </TableContainer>
         {/* {data && (
            <div ref={componentRef}>
               <ComponentToPrint
                  data={data}
               />
            </div>
         )} */}
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
         <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
         >
            {snackBarConfig.message}
         </Alert>
      </Snackbar>

      </div>
      </Protected>

   )
}

