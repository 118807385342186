import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@toolbox/constants/local-storage';
import { Login } from "@views/Login"
import { Home } from "@views/Home"
import { GeneratorTicket } from "@views/GeneratorTicket"
import { PrivateRoute, AuthRoute } from './PrivateRoute';
import { GeneratorDetail } from '@/views/GeneratorDetail';
import { GenerateNotIssued } from '@/views/GenerateNotIssued';
import { GenerateCanceled } from '@/views/GenerateCanceled';
import { GenerateProcessedCanceled } from '@/views/GenerateProcessedCanceled';
// ConsullBallot aún no está siendo llaamdo
import { ConsultBallot } from '@/views/ConsultBallot';
import { CalculateGenerate } from '@/views/CalculateGenerate';
import { FacturaDetail } from '@/views/FacturaDetail';
import { DocumentsError } from '@/views/DocumentsError';

const AllRoutes: React.FC = () => {

	const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);

   const moduleHome = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_HOME} component={Home} />,
   ];
   const moduleGeneratorTicket = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_GENERATOR_TICKET} component={GeneratorTicket} />,
   ];
   const moduleGeneratorDetail = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_GENERATOR_DETAIL} component={GeneratorDetail} />,
   ];

   const moduleFacturaDetail = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_FACTURA_DETAIL} component={FacturaDetail} />,
   ];
   const moduleCalculateGenerate = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CALCULATE_GENERATE} component={CalculateGenerate} />,
   ];
   const moduleDocumentsError = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DOCUMENTOS_ERROR} component={DocumentsError} />,
   ];

   const moduleGeneratorNotIssued = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_GENERATOR_NOT_ISSUED} component={GenerateNotIssued} />,
   ];
   const moduleGeneratorCanceled = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_GENERATOR_CANCELED} component={GenerateCanceled} />,
   ];
   const moduleGeneratoProcessedCanceled = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_GENERATOR_PROCESSED_CANCELED} component={GenerateProcessedCanceled} />,
   ];
   const moduleConsultBallot =[
      <PrivateRoute key={5} exact path={Routes.ROUTE_CONSULT_BALLOT} component={ConsultBallot} />
   ];
	const routes = useMemo(() => {
      return (
         <Router>
            <Switch>
               {moduleGeneratorTicket}
               {moduleGeneratorDetail}
               {moduleGeneratorNotIssued}
               {moduleGeneratorCanceled}
               {moduleGeneratoProcessedCanceled}
               {moduleConsultBallot}
               {moduleCalculateGenerate}
               {moduleFacturaDetail}
               {moduleDocumentsError}
               <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
               {<Route path='*' exact={true} component={() => {
                  return <Redirect to={Routes.ROUTE_LOGIN} />
               }} />}
            </Switch>
         </Router>
      )
	},// eslint-disable-next-line
	[JSON.stringify(dataUser)]);

	return routes;
}

export default AllRoutes;
