import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import {
   readLocalStorage,
   removeLocalStorage,
   saveLocalStorage
} from '@helpers/local-storage-helper';
import {
   KEY_AREA,
   KEY_ARRAY_MY_MENU,
   KEY_BODEGA,
   KEY_EMPRESA,
   KEY_OBJECT_MY_MENU,
   KEY_PAGOS,
   KEY_PARMS,
   KEY_TOKEN,
   KEY_TOOGLE_MENU,
   KEY_USER_DATA
} from '@constants/local-storage';
import { APP_AUTHORIZE_KEY } from '@defaults/app';
import { removeAuthCookie, setAuthCookie, readAuthCookie } from '@helpers/cookie.helper';
import { authenticationRepository } from '../repositories/Authentication.repository';
import { Authentication } from '../models/Authentication';
import { saleRepository } from '../repositories/Sale.repository';
import { saleService } from './Sale.service';

const currentUserSubject = new BehaviorSubject<Authentication | null>(init());

export const authenticationService = {
   login,
   logout,
   end,
   register,
   loginLoverse,
   loginFake,
   authCookie: readAuthCookie,
   currentUser: currentUserSubject.asObservable(),
   get currentUserValue () { return currentUserSubject.value }
};

function init() {
   const auth: Authentication = readLocalStorage(KEY_USER_DATA);
   axios.defaults.headers.common[APP_AUTHORIZE_KEY] = auth?.token || '';
   return auth;
}
function end() {
   removeLocalStorage(KEY_USER_DATA);
   removeLocalStorage(KEY_ARRAY_MY_MENU);
   removeLocalStorage(KEY_OBJECT_MY_MENU);
   removeLocalStorage(KEY_TOOGLE_MENU);
   removeAuthCookie();
   axios.defaults.headers.common[APP_AUTHORIZE_KEY] = null;
   currentUserSubject.next(null);
}
async function register(username: string, rut: string, password: string,key_loyverse: any){
 return await authenticationRepository.register(username,rut,password,key_loyverse)
}

function createExpireToken( s: number ): Date {
   let now = new Date();
   let time = now.getTime();
   var expireTime = time + 1000*s;
   now.setTime(expireTime);
   return now
}
async function loginLoverse(username: string, password: string,rut: string) : Promise<any> {
   return await authenticationRepository.loginLoverse(username,rut,password)
}

async function loginFake(username: string, password: string,rut: string) : Promise<any> {
   return await authenticationRepository.login(username,rut,password)
}

// async function login(email: string, credenciales: string, password: string) : Promise<Authentication> {
async function login(username: string, password: string,rut: string) : Promise<any> {
   try {
      const login = await authenticationRepository.login(username,rut,password);
      if (!!login.message) {
         return {
            user  : null,
            token : '',
            message : login.message
         }
      }

      const access_token = `${login.token || ''}`;
      axios.defaults.headers.common[APP_AUTHORIZE_KEY] = access_token;
       //const auth = await authenticationRepository.profile(access_token);
      // const expire_time = login.data?.expires_in ? createExpireToken(login.data?.expires_in) : 0;
      //  const loyverse = await authenticationRepository.loginLoverse(username,rut,password)
      //  if(loyverse.message==='Empresa integrada'){
      //  }
         const auth = await authenticationRepository.dataUser(access_token);
         const empresa = await authenticationRepository.dataEmpresa(access_token);
      const parms = {
         key: "azureservicemap",
         usuario: username,
         clave: password,
         rut: rut,
      }
      if (!!auth.mensaje) {
         // return {
         //    user  : null,
         //    token : '',
         //    mensaje : auth.mensaje
         // }
      }
      //auth.user.main_redirect = mainRedirect;
      saveLocalStorage(KEY_PARMS,parms);
      saveLocalStorage(KEY_TOKEN,access_token);
      saveLocalStorage(KEY_USER_DATA, auth);
      saveLocalStorage(KEY_EMPRESA,empresa);
      saveLocalStorage(KEY_TOOGLE_MENU, true);
      setAuthCookie(access_token, undefined);

      const token = readLocalStorage(KEY_TOKEN)
      // const areaNegocio = await saleService.getAreaNegocio(token);
      // saveLocalStorage(KEY_AREA,areaNegocio[0].id)
      // const bodegas = await saleService.getBodega(token);
      // saveLocalStorage(KEY_BODEGA,bodegas[0].id)
      // const formaPagos = await saleService.getTipoPagos(token);
      // saveLocalStorage(KEY_PAGOS,formaPagos[0].id)
     // setAuthCookie(access_token, expire_time === 0 ? undefined: { expires: expire_time });

      if(!!login.token) {
         currentUserSubject.next(login);
      }
         const authResponse = {...auth, data: login };
      return authResponse;

   } catch (e) {
      return {
         user: null, token: '', error: { code: 0, message: 'Error en obtener permisos' }
      }
   }
}

async function logout() {
   const rpta = await authenticationRepository.logout();
   if (!rpta.error) {
      end();
   }
   return rpta;
}

