import React, { useState, useEffect, useRef } from "react";
import CalculatorKey from "./CalculatorKey";
import { ComponentToPrint } from '../../GeneratorTicket/SeccionReporte';
import { useReactToPrint } from 'react-to-print';
import "./Calculator.css";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_AREA, KEY_BODEGA, KEY_EMPRESA, KEY_PAGOS, KEY_PARMS, KEY_TOKEN, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { saleService } from "@/service/services/Sale.service";
import { dateFormat, dateFormatTime } from "@/toolbox/helpers/date.helper";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Calculator() {
  const [data, setData] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [nextValue, setNextValue] = useState("0");
  const [op, setOp] = useState(null);

  const [iteracionOp, setIteracionOp] = useState("");
  const componentRef = useRef();
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

 });
 
  useEffect(() => {}, [op, nextValue, prevValue]);

  useEffect(() => {
    if (data !== null) {
       handlePrint();
    }
 }, [data])

  const CalculatorOperations = {
    "/": (firstValue, secondValue) => firstValue / secondValue,
    "*": (firstValue, secondValue) => firstValue * secondValue,
    "+": (firstValue, secondValue) => firstValue + secondValue,
    "-": (firstValue, secondValue) => firstValue - secondValue,
    "=": (firstValue, secondValue) => secondValue,
  };

  const performOperation = () => {
    let temp = CalculatorOperations[op](
      parseFloat(prevValue),
      parseFloat(nextValue)
    );
    setOp(null);
    setNextValue(String(temp));
    setPrevValue(null);
  };

  const handleNum = (number) => {
    setNextValue(nextValue === "0" ? String(number) : nextValue + number);
  };

  const insertDot = () => {
    if (!/\./.test(nextValue)) {
      setNextValue(nextValue + ".");
    }
  };
  const percentage = () => {
    setNextValue(parseFloat(nextValue) / 100);
    if (prevValue && nextValue === "") {
      setPrevValue(parseFloat(prevValue) / 100);
    }
  };
  const changeSign = () => {
    setNextValue(parseFloat(nextValue) * -1);
  };
  const clearData = () => {
    setNextValue("0");
    setPrevValue(0);
  };

  const handleOperation = (value) => {
    if (Number.isInteger(value)) {
      handleNum(parseInt(value, 10));
    } else if (value in CalculatorOperations) {
      if (op === null) {
        setOp(value);
        setPrevValue(nextValue);
        setNextValue("");
      }
      if (op) {
          console.log(prevValue);
          console.log(op)
          console.log(nextValue)
        setOp(value);
      }
      if (prevValue && op && nextValue) {
        performOperation();
      }
    } else if (value === "c") {
      clearData();
    } else if (value === "\xB1") {
      changeSign();
    } else if (value === ".") {
      insertDot();
    } else if (value === "%") {
      percentage();
    }
  };

  const Procesar = async() => {

        const total_bruto = nextValue;
        const total_neto = Math.ceil(total_bruto/1.19);

        let dataUser;
        dataUser = readLocalStorage(KEY_USER_DATA);
        let dataEmpresa;
        dataEmpresa = readLocalStorage(KEY_EMPRESA)
        const token = readLocalStorage(KEY_TOKEN)
        const rutEmpresa = readLocalStorage(KEY_PARMS)
        const bodega = readLocalStorage(KEY_BODEGA)
        const pagos = readLocalStorage(KEY_PAGOS)
        const area = readLocalStorage(KEY_AREA)
      
        const lineItems = [
                                { Codigo:1,
                                Cantidad: 1,
                                Descuento:0,
                                Precio: total_neto,
                                Afecto:true,
                                Bodega: bodega}
                            ]
        
        const enviarBoleta  = [
                                {
                                Encabezado: {
                                    Receptor: '66666666-6',
                                    MontoNeto: total_neto,
                                    //type=='ADDED'?total_neto:total_bruto,
                                    Descuento: 0, //this.state.descuento,
                                    TipoDocumento: '39',
                                    AreaNegocio: area,
                                    Observacion: 'observacion',
                                    Direccion: 'santiago',
                                },
                                Detalle: lineItems,
                                cliente: 'cliente',
                                Adicional: {
                                    Uno: pagos,
                                    Dos: dataUser.rut_usuario,
                                    Treinta: '123',
                                // Nueve: selectedPago.nombre,
                                },
                                fecha: new Date().toLocaleString(),
                                },
                            ]
        console.log(enviarBoleta)
        const boletagenerada = await saleService.enviarBoleta(token, enviarBoleta);
        console.log(boletagenerada)
        if(!boletagenerada[0]){
            alert('Los datos a procesar son inválidos')
           // setDisabled(false)
         }else{
            //setDisabled(false)
            if(boletagenerada[0].mensaje!=='Item guardado con exito.'){
               console.log(boletagenerada)
               alert(boletagenerada[0].mensaje)
              // setDisabled(false)
             }else{
               const resptimbre = boletagenerada[0].urlTimbre
              
                const newData = {
                  nameProyect: dataEmpresa?.razon,
                  tipoBoleta:  `BOLETA ELECTRÓNICA ·  ${boletagenerada[0].folio}`,
                  direccion: dataUser.sucursal[0].direccion,
                  cliente: rutEmpresa.rut,
                  anoreporte: dateFormatTime(new Date()),
                  horareporte: dateFormat(new Date()),
                  codigoProducto: "1000",
                  subTotal: "any",
                  montoNeto: moneyFormatInt(Math.ceil(total_neto)),
                  type: '',
                  iva: moneyFormatInt(total_bruto-total_neto),
                  montoTotal: moneyFormatInt(Math.ceil(total_bruto)),
                  timbre: resptimbre
                  //timbreEstatico: timbre
               }
               setData(newData);
               clearData();
             }
         }
  }

  return (
    <>
 
      <Grid container justifyContent='center'>
        <Typography variant='h3' mb={2}>Calculadora</Typography>
      </Grid>
     <div className="calculator">
      <div className="calculator-input">
        <div className="result">
          {nextValue} 
            {/* <Button variant="contained" color='success'

              sx={{ mb: 2, mt: 4 }}></Button> */}
            <IconButton sx={{ bgcolor: 'white', mb: 1, ml:2, mt:1}}  onClick={()=>{
        if(nextValue!='0' && op ==null && prevValue == null)
        {
            Procesar();
        }        
        }}>
              <ArrowForwardIcon color='primary' />
            </IconButton>
        </div>
         
      </div>
      <div className="calculator-keypad">
        <div className="keys-function">
          <CalculatorKey keyValue={"c"} onClick={handleOperation} />
          <CalculatorKey keyValue={"\xB1"} onClick={handleOperation} />
          <CalculatorKey keyValue={"%"} onClick={handleOperation} />
        </div>
        <div className="keys-operators">
          <CalculatorKey keyValue={"+"} onClick={handleOperation} />
          <CalculatorKey keyValue={"-"} onClick={handleOperation} />
          <CalculatorKey keyValue={"*"} onClick={handleOperation} />
          <CalculatorKey keyValue={"/"} onClick={handleOperation} />
          <CalculatorKey keyValue={"="} onClick={handleOperation} />
        </div>
        <div className="keys-numbers">
          <CalculatorKey keyValue={9} onClick={handleOperation} />
          <CalculatorKey keyValue={8} onClick={handleOperation} />
          <CalculatorKey keyValue={7} onClick={handleOperation} />
          <CalculatorKey keyValue={6} onClick={handleOperation} />
          <CalculatorKey keyValue={5} onClick={handleOperation} />
          <CalculatorKey keyValue={4} onClick={handleOperation} />
          <CalculatorKey keyValue={3} onClick={handleOperation} />
          <CalculatorKey keyValue={2} onClick={handleOperation} />
          <CalculatorKey keyValue={1} onClick={handleOperation} />
          <CalculatorKey
            className="key-dot"
            keyValue={"."}
            onClick={handleOperation}
          />
          <CalculatorKey
            className="key-zero"
            keyValue={0}
            onClick={handleOperation}
          />
        </div>
      </div>
    </div>
    {data && (
            <div ref={componentRef}>
               <ComponentToPrint
                  data={data}
               />
            </div>
         )}
    </>
   
  );
}

export default Calculator;