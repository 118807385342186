import React, { useState, useEffect, FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { TableData } from '@/components/common/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, TableContainer, Typography } from '@mui/material';
import { saleService } from '@service/services/Sale.service';

import { Props } from './ComponentToPrint.type'
import imagen from './../../../img/PDF417.png'
import { textAlign } from '@mui/system';
import { timbre } from '@/views/GenerateCanceled/SeccionReporte/Timbre';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';

export const ComponentToPrint: React.FC<Props> = (props: Props) => {


    return (
        <div
            // className={this.showhide()}
            // id='boletaimprimir'
            style={{ marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30, padding: "10px", display: 'flex', flexDirection: 'column' }}
        >
            <div style={{ width: "100%", lineHeight: '15px', flex: 1 }}>
                { !!props.data?.url_imagen && <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={props.data?.url_imagen} style={{ maxHeight: '100px', width: 'auto' }}/>
                </div>}
                <div style={{ textAlign: "center" }}>
                    <h3>R.U.T.: {props.data?.cliente}</h3>
                </div>
                {/* <h4>{this.props.datosempresa.razon} </h4> */}
                <div style={{ textAlign: "center" }}>
                    <h3>{props.data?.tipoBoleta} </h3>
                </div>
                <div style={{ textAlign: "center" }}>
                    <h3>N°: {props.data?.folio} </h3>
                </div>
                <div style={{ textAlign: "center" }}>
                    <h3>{props.data?.nameProyect}</h3>
                </div>
                <div style={{ textAlign: "center" }}>
                    <p style={{ fontSize: '14px' }}>{props.data?.giro}</p>
                </div>
            </div>
            <div style={{ flex: 1, textAlign: "center", lineHeight: '1px' }}>
                <div style={{ textAlign: "center" }}>
                    <h3>{props.data?.nombreSucursal}</h3>
                </div>
                <p style={{ fontSize: '14px' }}>{props.data?.direccion}</p>
                <p style={{ fontSize: '14px' }}>Teléfono: {props.data?.celular}</p>
            </div>
            <div style={{ borderTop: '1px dotted black', height: '10px', marginTop: '10px' }}></div>
            <div style={{ flex: 1, textAlign: "left", lineHeight: '1px' }}>
                <p>
                    <b>Fecha de Emisión:</b> {props.data?.anoreporte}{' '} {props.data?.horareporte}{' '}
                </p>
            </div>
            {
                props.data?.detalle_productos &&
                <>
                    <div style={{ borderTop: '1px dotted black', height: '10px', marginTop: '10px' }}></div>

                    <hr style={{ border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black' }} />
                    <div>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography sx={{ textAlign: "left", fontSize: '1.2em !important' }}>Artículo</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ textAlign: "right", fontSize: '1.2em !important' }}>Valor</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <hr style={{ border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black' }} />
                    </div>
                    <div>
                        <Grid container>
                            {(props.data?.detalle_productos || []).map((item, index) => (
                                <>
                                    <Grid item xs={12}>
                                        <Typography sx={{ textAlign: "left", fontSize: '1em !important' }}>{item?.item_name}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {/* <Typography sx={{ textAlign: "left", fontSize: '1em !important' }}>{item.quantity?.$numberInt || item.quantity } x {moneyFormatInt(Math.ceil(item?.price?.$numberInt ? item?.price?.$numberInt : (item?.total_money?.$numberInt ? item?.total_money?.$numberInt : item?.total_money )))}</Typography> */}
                                        <Typography sx={{ textAlign: "left", fontSize: '1em !important' }}>{item.quantity?.$numberInt || item.quantity?.$numberDouble || item.quantity } x {moneyFormatInt(Math.ceil(item?.price?.$numberInt ? item?.price?.$numberInt : (item?.price ? item?.price : item?.total_money?.$numberInt ? item?.total_money?.$numberInt : item?.total_money) ))}</Typography>
                                    
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}>{moneyFormatInt(Math.ceil(Number(item?.price?.$numberInt ? item?.price?.$numberInt : item?.price ? item?.price : item?.total_money?.$numberInt ? item?.total_money?.$numberInt : item?.total_money) * Number(item.quantity?.$numberInt|| item.quantity?.$numberDouble  || item.quantity)))}</Typography>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                </>
            }
            <div style={{ borderTop: '1px dotted black', height: '2px', marginTop: '10px' }}></div>

            <Grid container xs={12} style={{ padding: "5px", display: "flex", alignItems: "center", color: "#000" }}>
            {/* MONTO NETO */}
                <Grid item xs={6} style={{ marginTop: 0 }}>
                    <p >MONTO NETO</p>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right", fontWeight: "bold" }}>
                    <p>
                        {props.data?.montoNeto}{' '}
                    </p>
                </Grid>
            </Grid>

            <div style={{ borderTop: '1px dotted black', height: '2px', marginTop: '0px' }}></div>

            <Grid container xs={12} style={{ padding: "5px", display: "flex", alignItems: "center", color: "#000" }}>



                {/* IVA */}
                <Grid item xs={6}>
                    <p>{props.data.type == 'ADDED' ? 'TOTAL IVA 19%' : 'TOTAL IVA 19% (INCLUIDO)'}</p>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right", fontWeight: "bold" }}>
                    <p>
                        {props.data?.iva}{' '}
                    </p>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <div style={{ borderTop: '1px dotted black', height: '2px', marginTop: '0px' }}></div>
            </Grid>
            <Grid container xs={12} style={{ paddingLeft: "5px", paddingRight: '5px', display: "flex", alignItems: "center", color: "#000" }}>

                {/* TOTALES AGREGADOS*/}

                {props.data.agregadosTotal ?
                    (
                        <>
                            <Grid item xs={6}>
                                <p>OTROS CONCEPTOS</p>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right", fontWeight: "bold" }}>
                                <p>
                                    {props.data?.agregadosTotal}{' '}
                                </p>
                            </Grid>
                            <Grid xs={12}>
                                <hr />
                            </Grid>

                        </>
                    ) : (
                        <>
                        </>
                    )}

            </Grid>
            <Grid container xs={12} spacing={0} style={{ paddingLeft: "5px", paddingRight: '5px', display: "flex", alignItems: "center", color: "#000" }}>

                {/* MONTO TOTAL */}
                <Grid item xs={6}>
                    <p>MONTO TOTAL</p>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right", fontWeight: "bold", fontSize: "22px" }}>
                    <p >{props.data?.montoTotal}</p>
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                {<img
                    width='100%'
                    alt='Timbre Fiscal'
                    className='timbrefiscal'
                    //src={props.data?.timbre}
                    src={props.data.timbre != '' ? props.data.timbre : `data:image/png;base64, ${timbre}`}
                />}
                <p style={{ textAlign: 'center', fontSize: 12, margin: 'auto' }}>
                    Timbre Electrónico S.I.I. · Verifique Documento: http://www.sii.cl
                </p>
            </Grid>
        </div>
    )
}
