import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { Alert, Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, LinearProgress, MenuItem, Select, Snackbar, Stack, TableContainer, Typography } from '@mui/material';
import { saleService } from '@service/services/Sale.service';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from '../GeneratorTicket/SeccionReporte';
//import "./GeneratorTicket.sass";
import { Protected } from '@/components/layout/Protected';
import { useMediaQuery, useTheme } from '@mui/material';
import Calculator from "./components/Calculator";

export const CalculateGenerate: FunctionComponent = (props: any) => {

  
   const [open, setOpen] = useState(false);
   const componentRef = useRef();
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

  
  
   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <CircularProgress color="inherit" />
         </Backdrop>

    <div className='col-xs-12 col-md-offset-3 text-dark' style={{ padding: 40, textAlign: "center", minHeight: "100vh" }}>
         {/* <Grid container justifyContent='space-between' >
                  <Grid container justifyContent='center'>
                  <Typography variant='h3'>Calculadora</Typography>
                  </Grid>
         </Grid> */}
        
        <Calculator/>
         
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
         <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
         >
            {snackBarConfig.message}
         </Alert>
      </Snackbar>

      </div>
      </Protected>

   )
}

