export const ROUTE_HOME            = '/';
export const ROUTE_LOGIN           = '/login';
export const ROUTE_GENERATOR_TICKET= '/generar-ticket';
export const ROUTE_GENERATOR_DETAIL= '/generar-detalle';
export const ROUTE_GENERATOR_NOT_ISSUED= '/generar-no-emitido';
export const ROUTE_GENERATOR_CANCELED= '/generar-ticket-anulado';
export const ROUTE_GENERATOR_PROCESSED_CANCELED= '/generar-ticket-procesado-anulado';
export const ROUTE_CONSULT_BALLOT= '/consultar-datos';
export const ROUTE_CALCULATE_GENERATE   = '/calculadora-generar';
export const ROUTE_FACTURA_DETAIL = '/factura-detail';
export const ROUTE_DOCUMENTOS_ERROR = '/documentos-error'
