import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Button, FormControl, Grid, MenuItem, Select, Snackbar, useTheme, TableContainer, useMediaQuery, Typography, IconButton, Backdrop, CircularProgress, TablePagination, TextField } from '@mui/material';
import { saleService } from '@service/services/Sale.service';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from '../GeneratorTicket/SeccionReporte/ComponentToPrint';
//import "./GeneratorTicket.sass";
import { Link } from 'react-router-dom';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA, KEY_PARMS, KEY_TOKEN, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Protected } from '@/components/layout/Protected';
import { FormatDouble, moneyFormat, moneyFormatInt } from '@/toolbox/helpers/money.helper'
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper'
import { ROUTE_CONSULT_BALLOT } from '@/toolbox/constants/route-map';
import { cpuUsage } from 'process';
import RefreshIcon from '@mui/icons-material/Refresh';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { saleRepository } from '@/service/repositories/Sale.repository';
import { timbre } from '../GenerateCanceled/SeccionReporte/Timbre';

export const GeneratorDetail: FunctionComponent = (props: any) => {

   const [data, setData] = useState<any>(null);
   const [disabled, setDisabled] = useState(false)
   const [filter, setFilter] = useState(null);
   const [filterDoc, setFilterDoc] = useState<any>(0);
   const [open, setOpen] = useState(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [folio, setFolio] = useState('');
   const [fecha, setFecha] = useState('');
   const componentRef = useRef();
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
      getSalesPendingForPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const onChangeFolio = (e) =>{
      const regex = /^\d{0,10}$/;
      if (regex.test(e.target.value)) {
         setFolio(e.target.value);
      }
   }

   const verDocumento = async() => {
      let token = readLocalStorage(KEY_TOKEN);
      let parms = readLocalStorage(KEY_PARMS);
      let rutEmpresa = parms.rut
      await saleService.getVentaByFolio({folio: folio, rut: rutEmpresa}).then((data)=>{
         console.log(data)
         if(data.valid){
            setDataSale(data.data)
         }else{
            setSnackBarConfig({...snackBarConfig, open:true, message: data.message, severity:'error'})
         }
      })
   }

   // Modificar acá y traer Data dinámica
   const [dataSale, setDataSale] = useState<any>([])
   useEffect(() => {
      getSalesPending();
     // setFilter(0)
   }, [])

   async function getSalesPending() {
      setOpen(true)
      let parms = readLocalStorage(KEY_PARMS)
      parms = {...parms, page: page, perPage: rowsPerPage}
      const resp = await saleService.getSales(parms, 1)
      setDataSale(resp.contact)
      //.reverse((a, b) => a.payload.created_at < b.payload.created_at))
      setOpen(false)
   }
   async function getSalesPendingForPage(newPage) {
      setOpen(true)
      let parms = readLocalStorage(KEY_PARMS)
      parms = {...parms, page: newPage, perPage: rowsPerPage}
      const resp = await saleService.getSales(parms, 1)
      setDataSale(resp.contact)
      //.reverse((a, b) => a.payload.created_at < b.payload.created_at))
      setOpen(false)
   }

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         // backgroundColor: theme.palette.common.black,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: { fontSize: 14 },
   }));

   const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
      // hide last border
      '&:last-child td, &:last-child th': { border: 0 },
   }));

   useEffect(() => {
      if (data !== null) {
         handlePrint();
         setOpen(false)
      }
   }, [data])


   const recuperarData = async (i) => {
      const data = dataSale[i];
      console.log("DATA",data)
      let dataUser;
      dataUser = readLocalStorage(KEY_USER_DATA);
      const newData = {
         nameProyect: dataUser?.nombre,
         tipoBoleta: "BOLETA ELECTRÓNICA · FOLIO 283776",
         direccion: dataUser.sucursal[0].direccion,
         cliente: "Carlos Jordan Chinga Cornejo",
         anoreporte: data ? dateFormat(data.payload.created_at) + '' + dateFormatTime(data.payload.created_at) : "",
         codigoProducto: "A02123",
         subTotal: "any",
         montoNeto: data ? moneyFormat(data.payload.receipts[0].total_money - data.payload.receipts[0].total_tax) : "",
         iva: "51",
         montoTotal: data ? moneyFormat(data.payload.receipts[0].total_money) : ""
      }
      setData(newData);
      dataSale.splice(i, 1);
      const resp = await saleService.markSaleIssued(data._id, data.payload);
   }
   const handleChangeFilter = (e) => {
      setFilter(e.target.value)
      let newArray;
      const pos = e.target.value;
      switch (pos) {
         case 0:
            getSalesPending();
            break;
         case 1:
            newArray = dataSale.sort(function (a, b) {
               if (a.payload.created_at > b.payload.created_at) {
                  return 1;
               }
               if (a.payload.created_at < b.payload.created_at) {
                  return -1;
               }
               return 0;
            })
            setDataSale(newArray)
            break;
      }
   }

   const onChangeFecha = (e) => {
      setFecha(e.target.value)
   }

   const noEmitirData = async (i) => {
      const data = dataSale[i];
      console.log("DATA",data)
      const parms = readLocalStorage(KEY_PARMS)
      const resp = await saleService.getCambioEstado(parms, data._id, 2);
      getSalesPending();
   }

   const anularData = async (i) => {
      setDisabled(true)
      const data = dataSale[i];
      console.log("DATA",data)
      const parms = readLocalStorage(KEY_PARMS)
      const resp = await saleService.getCambioEstado(parms, data._id?.$oid || data._id, 4);
      getSalesPending();
      setDisabled(false)
   }

   const verDetalle = async (i) => {

      setDisabled(true)
      setOpen(true)

      const data = dataSale[i];
      const folio = data.folio;
      const resptimbre = data.detalles_softnet.urlTimbre ? data.detalles_softnet.urlTimbre : ''
      
      let propina=0;
      let type='';

      data.payload.receipts[0].total_taxes.map((value, i)=>{
         if(value.name=='Propina'){
            propina = value.money_amount?.$numberInt ? value.money_amount?.numberInt : value.money_amount
         }
         if(value.name=='IVA'){
            type= value.type
         }
      })
      let total_money = data.payload.receipts[0].total_money?.$numberInt ? data.payload.receipts[0].total_money?.$numberInt : data.payload.receipts[0].total_money
      let total_bruto = parseInt(total_money) -propina;
      let total_neto = total_bruto/1.19
      
      const rutEmpresa = readLocalStorage(KEY_PARMS)
      let dataUser = readLocalStorage(KEY_USER_DATA);
      let dataEmpresa = readLocalStorage(KEY_EMPRESA);
      
      console.log(dataUser)
      console.log(dataEmpresa)
      console.log(data)
      let detalle_productos;
      if(data.payload.receipts[0].dining_option == "Factura"){
         detalle_productos = data.payload.receipts[0].line_items;
      }

      const newData = {
         nameProyect: dataEmpresa?.razon,
         tipoBoleta: data.payload.receipts[0].dining_option == "Factura"? `FACTURA ELECTRÓNICA`:`BOLETA ELECTRÓNICA`,
         folio: folio,
         giro: dataEmpresa.giro1,
         nombreSucursal: dataUser?.sucursal[0]?.nombre,
         celular: dataEmpresa.telefono,
         direccion: dataUser.sucursal[0].direccion,
         cliente: rutEmpresa.rut,
         anoreporte: data ? dateFormat(data.payload.created_at) : '',
         horareporte: data ? dateFormatTime(data.payload.created_at) : '',
         codigoProducto: "1000",
         subTotal: "any",
         montoNeto: moneyFormatInt(Math.ceil(total_neto)) ,
         type: type,
         detalle_productos: data.payload.receipts[0].dining_option == "Factura"?detalle_productos:'',
         iva: moneyFormatInt(Math.ceil(total_bruto-total_neto)),
         montoTotal: moneyFormatInt(Math.ceil(total_bruto)),
         timbre: resptimbre,
         url_imagen : dataEmpresa?.url_imagen || ''
      }

      setDisabled(false)
      setData(newData)
   }
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
         <div style={{ padding: 40, textAlign: "center", minHeight: "100vh" }}>
            <Grid container justifyContent='space-between' mb={4}>
               {isMobile ? (
                  <Grid container justifyContent='center'>
                     <Typography variant='h4'>Venta con Boleta</Typography>
                  </Grid>

               ) : (
                  <Typography variant='h3'>Venta con Boleta</Typography>
               )}
               {isMobile ?
                  (<></>) :
                  (<Grid item pt={2}>
                     <Button variant="contained" color="success" onClick={() => getSalesPending()} >
                        Actualizar
                     </Button>
                  </Grid>)
               }
            </Grid>
            {isMobile ?
               (<>
                  <Grid container justifyContent='space-between' mb={3}>
                     <Grid item pt={2}>
                        <Button variant="contained" color="success" onClick={() => getSalesPending()} >
                           <RefreshIcon />
                        </Button>
                     </Grid>
                     <Grid
                        item mt={1}>
                        {/* <Grid >
               Filter:
            </Grid> */}
                        <Grid >
                           <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                              {/* <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel> */}
                              <Select
                                 labelId="demo-simple-select-standard-label"
                                 id="demo-simple-select-standard"
                                 value={filter}
                                 onChange={handleChangeFilter}
                                 label="Age"
                              >
                                 <MenuItem value={0}>Desc</MenuItem>
                                 <MenuItem value={1}>Asc</MenuItem>
                              </Select>
                           </FormControl>
                        </Grid>
                     </Grid>
                  </Grid>
               </>) : (
                  <Grid
                     container
                     direction="row"
                     justifyContent="space-between"
                     alignItems="center">
                     <Grid item xs={8} container
                     direction="row"
                     justifyContent="flex-start"
                     alignItems="center" >
                         <Grid >
                           Consulta de Documento por:
                        </Grid>
                        <Grid>
                        <Grid >
                           <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                              {/* <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel> */}
                              <Select
                                 labelId="demo-simple-select-standard-label"
                                 id="demo-simple-select-standard"
                                 value={filterDoc}
                                 onChange={(e)=>setFilterDoc(e.target.value)}
                                 label="Age"
                                 disabled
                              >
                                 <MenuItem value={0}>Folio</MenuItem>
                                 <MenuItem value={1}>Fechas</MenuItem>
                              </Select>
                           </FormControl>
                        </Grid>
                        </Grid>
                        {
                           filterDoc == 0 &&
                           <>
                              <Grid sx={{ mx: 1 }}>
                                 <TextField
                                    name='folio'
                                    id='folio'
                                    placeholder='Ingresa el folio'
                                    value={folio}
                                    type='string'
                                    variant='standard'
                                    onChange={e => onChangeFolio(e)}
                                 />
                              </Grid>
                              <Grid>
                                 <Button variant='contained' color='info' sx={{mr:1}} onClick={() => { verDocumento() }}>Consultar</Button>
                                 <Button variant='contained' color='secondary' onClick={() => { getSalesPending() }}>Limpiar</Button>
                              </Grid>
                           </>
                        }
                         {
                           filterDoc == 1 &&
                           <>
                              <Grid sx={{ mx: 1 }}>
                                 <TextField
                                    name='fecha'
                                    id='fecha'
                                    placeholder='Ingresa el fecha'
                                    value={fecha}
                                    type='date'
                                    variant='standard'
                                    onChange={e => onChangeFecha(e)}
                                 />
                              </Grid>
                              <Grid>
                                 <Button variant='contained' color='info' sx={{mr:1}} onClick={() => { verDocumento() }}>Consultar</Button>
                                 <Button variant='contained' color='secondary' onClick={() => { getSalesPending() }}>Limpiar</Button>
                              </Grid>
                           </>
                        }
                        
                     </Grid>
                     <Grid item xs={4}  container
                     direction="row"
                     justifyContent="flex-end"
                     alignItems="center">
                        <Grid >
                           Filtro:
                        </Grid>
                        <Grid >
                           <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                              {/* <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel> */}
                              <Select
                                 labelId="demo-simple-select-standard-label"
                                 id="demo-simple-select-standard"
                                 value={filter}
                                 onChange={handleChangeFilter}
                                 label="Age"
                              >
                                 <MenuItem value={0}>Desc</MenuItem>
                                 <MenuItem value={1}>Asc</MenuItem>
                              </Select>
                           </FormControl>
                        </Grid>
                     </Grid>

                  </Grid>
               )}

            <TableContainer component={Paper}>
               <Table aria-label="simple table" size='small'>
                  <TableHead sx={{ bgcolor: '#176ECA', color: '#fff' }}>
                     <TableRow >
                        {isMobile ? (
                           <>
                              <TableCell align="left" sx={{ color: '#fff', position: 'sticky', left: 0, background: '#176ECA' }}></TableCell>
                              <TableCell align="left" sx={{ color: '#fff', ml: 4 }}>FOLIO</TableCell>
                              <TableCell align="left" sx={{ color: '#fff' }}>N° RECIBO</TableCell>
                              <TableCell align="left" sx={{ color: '#fff', minWidth: { xs: '7rem', lg: '5rem' } }}>TOTAL</TableCell>
                              <TableCell align="left" sx={{ color: '#fff' }}>FECHA</TableCell>
                              <TableCell align="left" sx={{ color: '#fff' }}>PRODUCTOS</TableCell>
                              <TableCell align="left" sx={{ color: '#fff', minWidth: { xs: '7rem', lg: '5rem' } }}>TOTAL NETO</TableCell>
                           </>
                        ) : (
                           <>
                              <TableCell align="left" sx={{ color: '#fff' }}>FOLIO</TableCell>
                              <TableCell align="left" sx={{ color: '#fff' }}>N° RECIBO</TableCell>
                              <TableCell align="left" sx={{ color: '#fff' }}>PRODUCTOS</TableCell>
                              <TableCell align="left" sx={{ color: '#fff' }}>FECHA</TableCell>
                              <TableCell align="left" sx={{ color: '#fff', minWidth: { xs: '7rem', lg: '5rem' } }}>TOTAL NETO</TableCell>
                              <TableCell align="left" sx={{ color: '#fff', minWidth: { xs: '7rem', lg: '5rem' } }}>TOTAL</TableCell>
                              <TableCell align="left" sx={{ color: '#fff' }}>ACCIONES</TableCell>
                           </>
                        )}

                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {dataSale.map((row, i) => {

                   if(row.payload.receipts[0].dining_option != 'Factura'){
                     let cantidad = 0;
                     let length = row.payload.receipts[0].line_items.length;
                     let propina=0;

                     row.payload.receipts[0].total_taxes.map((value, i)=>{
                        if(value.name=='Propina'){
                           propina=  value.money_amount?.$numberInt ? value.money_amount?.$numberInt : value.money_amount                        
                        }

                     })
                     console.log( row.payload.receipts[0].total_money)
                     console.log(propina)
                     let total_money = row.payload.receipts[0].total_money?.$numberInt? row.payload.receipts[0].total_money?.$numberInt : row.payload.receipts[0].total_money
                     let total_bruto = parseInt(total_money) -propina;
                     let total_neto = total_bruto/1.19

                        return (
                           <StyledTableRow key={i}>
                              {isMobile ? (
                                 <>
                                    <StyledTableCell align="left" sx={{ position: 'sticky', left: 0, background: i%2==0?'#f3f3f3':'#fff' }}>
                                       {/* <Button variant="contained" onClick={() => { recuperarData(i) }} color="success" sx={{ mr: 2 }}>
                              Generar
                           </Button> */}
                           <IconButton sx={{bgcolor:'white',mb:1}} disabled={disabled} onClick={() => { verDetalle(i) }}>
                              <VisibilityIcon color='primary'/>
                           </IconButton>
                           <IconButton sx={{bgcolor:'white',mb:1}} disabled={disabled} onClick={() => { anularData(i) }}>
                              <HighlightOffIcon  color='error'/>
                           </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>{row.folio!=null?row.folio:'SIN FOLIO'}</StyledTableCell>
                        <StyledTableCell align="left">
                        {row.payload.receipts[0].receipt_number}
                        </StyledTableCell>
                        <StyledTableCell align="left">{moneyFormatInt(Math.ceil(total_bruto))}</StyledTableCell>
                        <StyledTableCell align="left">{dateFormat(row.payload.created_at)+' '+dateFormatTime(row.payload.created_at)}</StyledTableCell>
                        <StyledTableCell align="left">
                           {
                           row.payload.receipts[0].line_items.map((value, i) => {
                              const quan = value.quantity?.$numberInt|| value.quantity?.$numberDouble  || value.quantity
                              cantidad = cantidad + parseInt(quan);
                              if(i===length-1)
                              {
                                 return (
                                 FormatDouble(cantidad)
                                 )
                              }else
                              {

                              }
                           })}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                  {moneyFormatInt(Math.ceil(total_neto))}
                     </StyledTableCell>
                           </>
                        ):(
                           <>
                           <StyledTableCell>{row.folio!=null?row.folio:'SIN FOLIO'}</StyledTableCell>
                        <StyledTableCell align="left">
                        {row.payload.receipts[0].receipt_number}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                           {
                           row.payload.receipts[0].line_items.map((value, i) => {
                              console.log(cantidad)
                              console.log(value.quantity.$numberInt)
                              const quan = value.quantity?.$numberInt|| value.quantity?.$numberDouble  || value.quantity
                              cantidad = cantidad + parseInt(quan);
                              if(i===length-1)
                              {
                                 console.log(cantidad)
                                 return (
                                    FormatDouble(cantidad)
                                 )
                              }else
                              {
                                             }
                                          })}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{dateFormat(row.payload.created_at) + ' ' + dateFormatTime(row.payload.created_at)}</StyledTableCell>
                                    <StyledTableCell>{moneyFormatInt(Math.ceil(total_neto))}</StyledTableCell>
                                    <StyledTableCell>{moneyFormatInt(Math.ceil(total_bruto))}</StyledTableCell>

                                    <StyledTableCell align="left">
                                       {/* <Button variant="contained" onClick={() => { recuperarData(i) }} color="success" sx={{ mr: 2 }}>
                              Generar
                           </Button> */}
                                       <Button disabled={disabled} onClick={() => { anularData(i) }} variant="contained" color="error">Invalidar</Button>
                                       <Button disabled={disabled} onClick={() => { verDetalle(i) }} variant="contained" color="primary">Ver</Button>
                                    </StyledTableCell>
                                 </>
                              )}

                           </StyledTableRow>
                        )
                   }
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               component="div"
               count={100}
               page={page}
               rowsPerPage={rowsPerPage}
               rowsPerPageOptions={[5, 10, 15]}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
               labelRowsPerPage="Filas por página"
               labelDisplayedRows={({ from, to, count }) =>
                 `${from}-${to}`
               }
            />
            {data && (
               <div ref={componentRef}>
                  <ComponentToPrint
                     data={data}
                  />
               </div>
            )}
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>

         </div>
      </Protected>

   )
}

