import React, { useState, useEffect, FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { TableData } from '@/components/common/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, TableContainer, Typography } from '@mui/material';
import { saleService } from '@service/services/Sale.service';

import {Props} from './ComponentPrintFactura.type'
import imagen from './../../../img/PDF417.png'
import { textAlign } from '@mui/system';
import { timbre } from '@/views/GenerateCanceled/SeccionReporte/Timbre';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';

export const ComponentPrintFactura: React.FC<Props> = (props: Props) => {

console.log(props)
    return (
        <div
            // className={this.showhide()}
            // id='boletaimprimir'
            style={{  marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30 ,padding:"10px"}}
        >
            <Grid container justifyContent={'center'}>
                <div style={{border:'2px solid black', paddingLeft:'2px', paddingRight:'2px', minWidth:'90%'}} >
                    <p style={{textAlign:"center"}}>
                        <b>RUT:</b> {props.data?.cliente}{' '}
                    </p>
                    <h4 style={{textAlign:"center"}}>{props.data?.tipoBoleta} </h4>
                    <h4 style={{textAlign:"center"}}> N° {props.data?.folio} </h4>
                </div>
            </Grid>
            <div style={{textAlign:"center"}}>
            <p style={{fontSize:'12px', fontWeight:700}}>S.I.I. {props.data?.datoEmpresa?.unidad_sii}</p>
            </div>
            <div className='encabezadoboleta' style={{width:"100%"}}>
                <div style={{textAlign:"center"}}>
                    <p style={{fontSize:'12px', fontWeight:700}}>{props.data?.nameProyect}</p>
                    <p style={{fontSize:'12px', fontWeight:700}}>{props.data?.datoEmpresa?.giro1}</p>
                    <p style={{fontSize:'12px', fontWeight:700}}>{props.data?.datoEmpresa?.giro2}</p>
                    <p style={{fontSize:'12px', fontWeight:700}}>{props.data?.datoEmpresa?.direccion}</p>
                    <p style={{fontSize:'12px', fontWeight:700}}>{props.data?.datoEmpresa?.comuna+' '+props.data?.datoEmpresa?.comuna } </p>
                </div>
                <div style={{textAlign:"right"}}>
                    <p style={{fontSize:'12px', fontWeight:700}}><b>Fecha de Emisión:</b> {props.data?.anoreporte+' '+props.data?.horareporte}</p>
                </div>
            </div>
            <Grid container spacing={0} >
                <Grid item xs={5} style={{ textAlign: 'left', padding: 0 }} paddingBottom={0}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>Rut</p>
                </Grid>
                <Grid item xs={7} paddingBottom={0}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>: {props.data?.receptor?.RUTRecep}</p>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'left' }} paddingTop={0}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>Razón Social</p>
                </Grid>
                <Grid item xs={7}  paddingTop={0}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>: {props.data?.receptor?.RznSocRecep}</p>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'left' }}>
                        <p style={{ fontSize: '12px', fontWeight: 700 }}>Giro</p>
                </Grid>
                <Grid item xs={7}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>: {props.data?.receptor?.GiroRecep}</p>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'left' }}>
                        <p style={{ fontSize: '12px', fontWeight: 700 }}>Direccion</p>
                </Grid>
                <Grid item xs={7}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>: {props.data?.receptor?.DirRecep}</p>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'left' }}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>Comuna/Ciudad</p>
                </Grid>
                <Grid item xs={7}>
                    <p style={{ fontSize: '12px', fontWeight: 700 }}>: {props.data?.receptor?.CmnaRecep+ ' - '+ props.data?.receptor?.CiudadRecep}</p>
                </Grid>

            </Grid>
           {
            props.data?.detalle_productos &&
            <>
                    <hr style={{ border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black' }} />
                    <div>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography sx={{ textAlign: "left", fontSize: '1.2em !important' }}>Artículo</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ textAlign: "right", fontSize: '1.2em !important' }}>Valor</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <hr style={{ border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black' }} />
                    </div>
                    <div>
                        <Grid container>
                            {(props.data?.detalle_productos || []).map((item, index) => (
                                <>
                                    <Grid item xs={12}>
                                        <Typography sx={{ textAlign: "left", fontSize: '1em !important' }}>{item?.item_name}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ textAlign: "left", fontSize: '1em !important' }}>{item.quantity} x {moneyFormatInt(Math.ceil(item?.price ? item?.price : item?.total_money))}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}>{moneyFormatInt(Math.ceil(Number(item?.price ? item?.price : item?.total_money) * Number(item.quantity)))}</Typography>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                    <Grid xs={12}>
                    <hr/>
                </Grid>
            </>
           }
            <Grid container xs={12} style={{padding:"5px",display:"flex", alignItems:"center",color:"#000"}}>
                {/* MONTO NETO */}
                <Grid item xs={6}>
                    <p>MONTO NETO</p>
                </Grid>
                <Grid item xs={6} style={{textAlign:"right",fontWeight:"bold"}}>
                    <p >
                        {props.data?.montoNeto}{' '}
                    </p>
                </Grid>

                <Grid xs={12}>
                    <hr/>
                </Grid>


                {/* IVA */}
                <Grid item xs={6}>
                    <p>{props.data.type=='ADDED'?'TOTAL IVA 19%':'TOTAL IVA 19% (INCLUIDO)'}</p>
                </Grid>
                <Grid item xs={6} style={{textAlign:"right",fontWeight:"bold"}}>
                    <p>
                        {props.data?.iva}{' '}
                    </p>
                </Grid>
                <Grid xs={12}>
                    <hr/>
                </Grid>

                {/* TOTALES AGREGADOS*/}

                {props.data.agregadosTotal?
                (
                  <>
                  <Grid item xs={6}>
                    <p>OTROS CONCEPTOS</p>
                  </Grid>
                  <Grid item xs={6} style={{textAlign:"right",fontWeight:"bold"}}>
                     <p>
                           {props.data?.agregadosTotal}{' '}
                     </p>
                  </Grid>
                  <Grid xs={12}>
                     <hr/>
                  </Grid>

                  </>
                ):(
                  <>
                  </>
                )}


                {/* MONTO TOTAL */}
                <Grid item xs={6}>
                <p style={{marginBottom: "10px"}}>MONTO TOTAL</p>
                </Grid>
                <Grid item xs={6} style={{textAlign:"right", fontWeight:"bold", fontSize:"22px"}}>
                <p >{props.data?.montoTotal}</p>
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                {<img
                    width='100%'
                    alt='Timbre Fiscal'
                    className='timbrefiscal'
                  //src={props.data?.timbre}
                  src={props.data.timbre!=''?props.data.timbre:`data:image/png;base64, ${timbre}`}
                />}
                <p style={{ textAlign: 'center', fontSize: 12, margin: 'auto' }}>
                    Timbre Electrónico S.I.I. · Verifique Documento: http://www.sii.cl
                </p>
                {/* <hr />
        <hr /> */}
            </Grid>
        </div>
    )
}
