import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, MenuItem, Select, Snackbar, TableContainer, Typography } from '@mui/material';
import { saleService } from '@service/services/Sale.service';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './SeccionReporte';
//import "./GeneratorTicket.sass";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_PARMS, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Protected } from '@/components/layout/Protected';
import { FormatDouble, moneyFormat, moneyFormatInt } from '@/toolbox/helpers/money.helper'
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper'
import { useMediaQuery, useTheme } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

export const GenerateCanceled: FunctionComponent = (props: any) => {

   const [data, setData] = useState(null);
   const [filter, setFilter] = useState(null);
   const componentRef = useRef();
   const theme = useTheme();
   const [disabled, setDisabled] = useState(false)
   const [open, setOpen] = useState(false);
   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   // Modificar acá y traer Data dinámica
   const [dataSale, setDataSale] = useState<any>([])
   useEffect(() => {
      getSalesPending();
      //setFilter(0)
   }, [])

   async function getSalesPending() {
      setOpen(true)
      const parms = readLocalStorage(KEY_PARMS)
      const resp = await saleService.getSales(parms,3)
      setDataSale(resp.contact)
      setOpen(false)
   }

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         // backgroundColor: theme.palette.common.black,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: { fontSize: 14 },
   }));

   const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
      // hide last border
      '&:last-child td, &:last-child th': { border: 0 },
   }));

   useEffect(() => {
      if (data !== null) {
         handlePrint();
      }
   }, [data])

   const RecuperarPendiente = async (i) => {
      setDisabled(true)
      const data = dataSale[i];
      const parms = readLocalStorage(KEY_PARMS)
      const resp = await saleService.getCambioEstado(parms,data._id,0);
      getSalesPending();
      setDisabled(false)
   }

   const handleChangeFilter = (e) => {
      setFilter(e.target.value)
      let newArray;
      const pos = e.target.value;
      switch (pos) {
         case 0:
            getSalesPending();
            break;
         case 1:
            newArray = dataSale.sort(function (a, b) {
               if (a.payload.created_at > b.payload.created_at) {
                  return 1;
               }
               if (a.payload.created_at < b.payload.created_at) {
                  return -1;
               }
               return 0;
            })
            setDataSale(newArray)
            break;
      }
   }

   const recuperarData = async (i) => {
      const data = dataSale[i];
      let dataUser;
      dataUser = readLocalStorage(KEY_USER_DATA);
      const newData = {
         nameProyect: dataUser?.nombre,
         tipoBoleta: "BOLETA ELECTRÓNICA · FOLIO 283776",
         direccion: dataUser.sucursal[0].direccion,
         cliente: "Carlos Jordan Chinga Cornejo",
         anoreporte: data ? dateFormat(data.payload.created_at)+''+dateFormatTime(data.payload.created_at) : "",
         codigoProducto: "A02123",
         subTotal: "any",
         montoNeto: data ? moneyFormat(data.payload.receipts[0].total_money - data.payload.receipts[0].total_tax) : "",
         iva: "51",
         montoTotal: data ? moneyFormat(data.payload.receipts[0].total_money) : ""
      }
      setData(newData);
      dataSale.splice(i,1);
      const resp= await saleService.markSaleIssued(data._id,data.payload);
   }
   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
  <div className='col-xs-12 col-md-offset-3 text-dark' style={{ padding: 40, textAlign: "center", minHeight: "100vh" }}>
  <Grid container justifyContent='space-between' mb={4}>
            {isMobile?(
               <Grid container justifyContent='center'>
                  <Typography variant='h4'>Pedidos Anulados</Typography>
               </Grid>
               ):(
                  <Typography variant='h3'>Pedidos Anulados</Typography>
               )}

            {isMobile?
            (<></>):
            (<Grid item pt={2}>
               <Button variant="contained" color="success" onClick={()=>getSalesPending()} >
                  Actualizar
               </Button>
            </Grid>)
            }
         </Grid>
         {isMobile?
         (<>
         <Grid container justifyContent='space-between' mb={3}>
            <Grid item pt={2}>
               <Button variant="contained" color="success" onClick={()=>getSalesPending()} >
                  <RefreshIcon/>
               </Button>
            </Grid>
            <Grid
            item mt={1}>
            {/* <Grid >
               Filter:
            </Grid> */}
            <Grid >
               <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                  <Select
                     labelId="demo-simple-select-standard-label"
                     id="demo-simple-select-standard"
                     value={filter}
                     onChange={handleChangeFilter}
                     label="Age"
                  >
                     <MenuItem value={0}>Desc</MenuItem>
                     <MenuItem value={1}>Asc</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
            </Grid>
         </Grid>
         </>):(
            <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid >
               Filtro:
            </Grid>
            <Grid >
               <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                  {/* <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel> */}
                  <Select
                     labelId="demo-simple-select-standard-label"
                     id="demo-simple-select-standard"
                     value={filter}
                     onChange={handleChangeFilter}
                     label="Age"
                  >
                     <MenuItem value={0}>Desc</MenuItem>
                     <MenuItem value={1}>Asc</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
             </Grid>
         )}

         <TableContainer component={Paper}>
            <Table aria-label="simple table" size='small'>
               <TableHead sx={{bgcolor:'#176ECA', color:'#fff'}}>
                  <TableRow >
                  {isMobile?(
                     <>
                     <TableCell sx={{color:'#fff', position:'sticky', left:0, background:'#176ECA'}}></TableCell>
                     <TableCell align="center" sx={{color:'#fff', minWidth:'5rem'}} >N° RECIBO</TableCell>
                     <TableCell align="center" sx={{color:'#fff',minWidth:'4rem'}}>TOTAL</TableCell>
                     <TableCell align="center" sx={{color:'#fff',minWidth:'6rem'}}>FECHA</TableCell>
                     <TableCell align="center" sx={{color:'#fff'}}>PRODUCTOS</TableCell>
                     <TableCell align="center" sx={{color:'#fff',minWidth:{xs:'7rem',lg:'5rem'}}}>TOTAL NETO</TableCell>
                     </>
                  ):(
                     <>
                     <TableCell align="center" sx={{color:'#fff'}} >N° RECIBO</TableCell>
                     <TableCell align="center" sx={{color:'#fff'}}>PRODUCTOS</TableCell>
                     <TableCell align="center" sx={{color:'#fff'}}>FECHA</TableCell>
                     <TableCell  sx={{color:'#fff',minWidth:{xs:'7rem',lg:'5rem'}}}>TOTAL NETO</TableCell>
                     <TableCell  sx={{color:'#fff',minWidth:{xs:'7rem',lg:'5rem'}}}>TOTAL</TableCell>
                     <TableCell align="center" sx={{color:'#fff',minWidth:{xs:'2rem',lg:'5rem'}}}>ACCIONES</TableCell>
                     </>
                  )}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {dataSale.map((row, i) => {
                     let cantidad = 0;
                     let length = row.payload.receipts[0].line_items.length;
                     let propina=0;

                     row.payload.receipts[0].total_taxes.map((value, i)=>{
                        if(value.name=='Propina'){
                           propina=  value.money_amount
                        }

                     })

                     let total_bruto = row.payload.receipts[0].total_money-propina;
                     let total_neto = total_bruto/1.19


                     return(
                        <StyledTableRow key={i}>
                           {isMobile?(
                                   <>
                                   <StyledTableCell align="center" sx={{position:'sticky', left:0, background: i%2==0?'#f3f3f3':'#fff' }}>
                                        <IconButton sx={{bgcolor:'white',mb:1}} disabled={disabled}  onClick={() => { RecuperarPendiente(i) }}>
                                           <RestartAltIcon  color='primary'/>
                                        </IconButton>
                                   </StyledTableCell>
                                   <StyledTableCell align="center">
                       {row.payload.receipts[0].receipt_number}
                      </StyledTableCell>
                      <StyledTableCell align="left">{moneyFormatInt(Math.ceil(total_bruto))}</StyledTableCell>
                      <StyledTableCell align="center">{dateFormat(row.payload.created_at)+' '+dateFormatTime(row.payload.created_at)}</StyledTableCell>
                      <StyledTableCell align="center">
                       {
                          row.payload.receipts[0].line_items.map((value, i) => {
                             cantidad = cantidad + value.quantity;
                             if(i===length-1)
                             {
                                return (
                                 FormatDouble(cantidad)
                                )
                             }else
                             {

                             }
                          })}
                       </StyledTableCell>
                       <StyledTableCell align="left">
                                      {moneyFormatInt(Math.ceil(total_neto))}
                                         </StyledTableCell>
                                   </>
                        ):(
                           <>
                           <StyledTableCell align="center">
                           {row.payload.receipts[0].receipt_number}
                          </StyledTableCell>
                           <StyledTableCell align="center">
                           {
                              row.payload.receipts[0].line_items.map((value, i) => {
                                 cantidad = cantidad + value.quantity;
                                 if(i===length-1)
                                 {
                                    return (
                                       FormatDouble(cantidad)
                                    )
                                 }else
                                 {

                                 }
                              })}
                           </StyledTableCell>
                           <StyledTableCell align="center">{dateFormat(row.payload.created_at)+' '+dateFormatTime(row.payload.created_at)}</StyledTableCell>
                           <StyledTableCell>{moneyFormatInt(Math.ceil(total_neto))}</StyledTableCell>
                           <StyledTableCell>{moneyFormatInt(Math.ceil(total_bruto))}</StyledTableCell>
                           <StyledTableCell align="center">
                             <Button onClick={() => { RecuperarPendiente(i) }} variant='contained' sx={{bgcolor:'#f7ac33',ml:1}}>Recuperar</Button> 
                              </StyledTableCell>
                                    </>

                                 )}


                     </StyledTableRow>
                     )
})}
               </TableBody>
            </Table>
         </TableContainer>
         {/* {data && (
            <div ref={componentRef}>
               <ComponentToPrint
                  data={data}
               />
            </div>
         )} */}
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
         <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
         >
            {snackBarConfig.message}
         </Alert>
      </Snackbar>

      </div>
      </Protected>

   )
}

